import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NavBar from "./components/Navbar/NavBar";
import DataSecurity from "./pages/DataSecurity";
import Home from "./pages/Home";
import Impressum from "./pages/Impressum";
import "./styles/components/contactsection.css";
import "./styles/components/footersection.css";
import "./styles/components/gallerysection.css";
import "./styles/components/motivationsection.css";
import "./styles/components/navbar.css";
import "./styles/components/scrollbanner.css";
import "./styles/components/welcomesection.css";
import "./styles/pages/App.css";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datasecurity" element={<DataSecurity />} />
      </Routes>
    </Router>
  );
}

export default App;
