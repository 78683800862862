import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { constants } from "../../common/constants";

const ContactSection = () => {
  const openPhoneLink = (phoneNumber: string) => {
    // Sicherstellen, dass die Telefonnummer nur Ziffern enthält, um vor böswilligen Eingaben zu schützen
    const cleanPhoneNumber = phoneNumber.replace(/\D/g, "");
    window.open(`tel:${cleanPhoneNumber}`);
  };

  const openEmailLink = () => {
    // Verwendung von encodeURIComponent, um sicherzustellen, dass die E-Mail-Adresse korrekt formatiert ist
    const email = constants.EMAIL;
    window.location.href = `mailto:${encodeURIComponent(email)}`;
  };

  const openMapLink = () => {
    // Verwendung von encodeURIComponent, um sicherzustellen, dass die Adresse korrekt formatiert ist
    const address = "Dekan-Wagner-Straße 22, 84032 Altdorf";
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        address
      )}`
    );
  };

  const openFacebookLink = () => {
    // Verwendung von rel="noopener noreferrer" für Sicherheit bei window.open
    window.open(
      "https://www.facebook.com/people/MO-PHO-Restaurant/100092262050294/",
      "_blank",
      "noopener noreferrer"
    );
  };

  return (
    <div className="contact-section-outer" data-aos="zoom-in-up">
      <h2 className="section-title" data-aos="fade-up">
        Kontakt
      </h2>
      <div className="contact-section-inner">
        <div className="contact-text" data-aos="fade-right">
          <p>
            Wir als Restaurant <span className="restaurant-name">Moon</span>{" "}
            legen großen Wert auf Kundenzufriedenheit und sind stets für Ihre
            Anliegen und Wünsche da. <br /> <br /> Sie können uns per E-Mail
            oder Telefon kontaktieren, um Fragen, Anregungen oder Feedback
            mitzuteilen. <br /> <br /> Besuchen Sie uns gerne persönlich an
            unserem Standort, da wir kontinuierlich bestrebt sind, unseren
            Service und unsere Angebote zu verbessern. <br /> <br /> Wir freuen
            uns auf Ihre Anfragen und darauf, Sie im Restaurant{" "}
            <span className="restaurant-name">Moon</span> begrüßen zu dürfen!
          </p>
        </div>
        <div className="information-icons">
          <div className="contact-icons">
            <div className="contact-icon" data-aos="fade-left">
              <div className="icon">
                <LocalPhoneIcon />
              </div>
              <div className="text" id="contact-section">
                <div className="title">Telefon</div>
                <div className="multiNumber">
                  <div
                    className="description"
                    onClick={() => openPhoneLink(constants.PHONE_PRIMARY)}
                  >
                    {constants.PHONE_PRIMARY}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="contact-icon"
              data-aos="fade-left"
              onClick={openEmailLink}
            >
              <div className="icon">
                <EmailIcon />
              </div>
              <div className="text">
                <div className="title">Email</div>
                <div className="description">{constants.EMAIL}</div>
              </div>
            </div>
            <div
              className="contact-icon"
              data-aos="fade-left"
              onClick={openMapLink}
            >
              <div className="icon">
                <LocationOnIcon />
              </div>
              <div className="text">
                <div className="title">Adresse</div>
                <div className="description">
                  Dekan-Wagner-Straße 22, 84032 Altdorf
                </div>
              </div>
            </div>
            <div
              className="contact-icon"
              data-aos="fade-left"
              onClick={openFacebookLink}
            >
              <div className="icon">
                <FacebookIcon />
              </div>
              <div className="text">
                <div className="title">Folge uns</div>
                <div className="description">Besuche unsere Facebook-Seite</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
