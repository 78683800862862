import FacebookIcon from "@mui/icons-material/Facebook";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease",
      mirror: false,
    });
  }, []);

  return (
    <footer className="footer-section-outer">
      <div className="footer-section-inner">
        <div className="footer-copyright">Copyright © {currentYear}</div>
        <div className="footer-brand" data-aos="fade-right">
          <span className="footer-restaurant-name">Moon</span>
        </div>

        <hr className="footer-divider" />
        <div className="footer-social-icons">
          <div className="social-icon">
            {/* Öffne externe Links in einem neuen Tab */}
            <a
              href="https://www.facebook.com/profile.php?id=100092262050294"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
