import React, { useEffect, useState } from "react";
import {
  ClearAll as ClearAllIcon,
  Facebook,
  Menu as MenuIcon,
} from "@mui/icons-material";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function SocialMediaIcons() {
  return (
    <Nav className="ms-auto social-icons">
      <Nav.Link
        href="https://www.facebook.com/profile.php?id=100092262050294"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Facebook />
      </Nav.Link>
    </Nav>
  );
}

function NavBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    function handleScroll() {
      setIsScrolled(window.pageYOffset > 0);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClass = isScrolled ? "scrolled" : "";

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLinkClick = () => {
    setIsExpanded(false);
  };

  const toggleClass = isExpanded ? "expanded" : "";

  const scrollToSection = (sectionId: string) => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
    handleLinkClick();
  };

  return (
    <Navbar
      expand="lg"
      className={`navbar ${navbarClass} fixed-top`}
      expanded={isExpanded}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={handleLinkClick}>
          Moon
        </Navbar.Brand>
        <Navbar.Toggle
          className={`white-toggle ${toggleClass}`}
          aria-controls="basic-navbar-nav"
          onClick={handleToggle}
        >
          {isExpanded ? (
            <ClearAllIcon style={{ fontSize: "30px" }} />
          ) : (
            <MenuIcon style={{ fontSize: "30px" }} />
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => scrollToSection("photo-section")}>
              Galerie
            </Nav.Link>
            <Nav.Link onClick={() => scrollToSection("contact-section")}>
              Reservierung & Kontakt
            </Nav.Link>
            <Dropdown>
              <Dropdown.Toggle as={Nav.Link} id="impressum-dropdown">
                Rechtliches
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to="/impressum"
                  onClick={handleLinkClick}
                >
                  Impressum
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/datasecurity"
                  onClick={handleLinkClick}
                >
                  Datenschutz
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <SocialMediaIcons />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
